<template>
  <div class="footer">
    <hr>
    <div class="row">
      <div class="col-sm-3"> <img
          alt="Lancrop Logo"
          class="footerLancropLogo"
          src="../assets/LancropLogo.png"
        ></div>
      <div class="col-sm-3"> Wellington Road, The Industrial Estate
        <br>Pocklington, York. YO42 1DN
        <br>Tel: 01759 305116 Fax: 01759 306955
        <br>Email:
        <a href="mailto:ypl.laboratory@yara.com">ypl.laboratory@yara.com</a>
        <br></div>
      <div class="col-sm-3"> <img
          alt="ISO9001 logo"
          src="../assets/ISO9001Logo.png"
        ></div>
      <div class="col-sm-3">
        All analytical services are subject to our
        Terms of Supply.
        <br>

        <a href="/#/terms">Yara UK terms of supply of analytical services</a>
        <br>
        <a href="https://www.yara.com/privacy-and-legal/digital-farming-privacy/">Privacy policy</a>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        Lancrop Laboratories is a trading name of Yara UK Limited
        <br>
        Yara UK Limited, Registered Office,Manor Place, Wellington Road, The Industrial Estate, Pocklington, YO42 1DN, UK. Registered in England No. 03818176
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "FooterArea"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped language='scss'>
.footer:before {
  clear: both;
}
.footer {
  margin-top: 0.5rem ;
  padding:0.5rem 0.5rem;
  font-size: 0.8rem;
  /*margin-left: auto;
  margin-right: auto;
  width: 908px;*/
  max-width: 908px;
}
.footerLancropLogo {
}
table {
  width: 100%;
}
td {
  text-align: left;
  vertical-align: top;
  padding-right: 3em;
}
.footer img {
  margin:0.5rem 0rem;
  height: 5rem;
}
</style>
