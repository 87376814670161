
<script>
const articleList = [
  {
    title: "How to take a sample (soil, leaf and tissue)",
    filename: "Lancrop_How_To_Take.pdf",
    description: "",
    image: "images/Sampling.jpg",
    directDownload: true,
    otherArticle: true,
    samplingArticle: true,
    soilArticle: true,
    leafArticle: true
  },
  {
    title: "Understanding soil pH",
    filename: "Lancrop_Understanding soil pH.pdf",
    description: "",
    image: "images/AvailabilityOfNutrientForSoilpH.png",
    directDownload: false,
    soilArticle: true
  },
  {
    title: "Potato Leaf Analysis Update June 2020",
    filename: "Lancrop_Potato Leaf Analysis Update June 2020.pdf",
    description: "",
    image: "images/AvailabilityOfNutrientForSoilpH.png",
    directDownload: false,
    soilArticle: true
  },
  {
    title: "Maize Leaf Analysis Update June 2020",
    filename: "Lancrop_Maize Leaf Analysis Update June 2020.pdf",
    description: "",
    image: "images/AvailabilityOfNutrientForSoilpH.png",
    directDownload: false,
    soilArticle: true
  },
  {
    title: "SAC Index System",
    filename: "Lancrop_SAC Index System.pdf",
    description: "",
    image: "images/adas.jpg",
    directDownload: true,
    otherArticle: true
  },
  {
    title: "Understanding Soil Nutrient Interactions",
    filename: "Lancrop_Understanding Soil Nutrient Interactions.pdf",
    description: "",
    image: "images/NutrientInteractionCircle.png",
    directDownload: false,
    soilArticle: true
  },
  {
    title: "Understanding Soil Texture",
    filename: "Lancrop_Understanding Soil Texture.pdf",
    description: "",
    image: "images/SoilTextureTriangle.png",
    directDownload: false,
    soilArticle: true
  },
  {
    title: "Interpreting Soil Analysis",
    filename: "Lancrop_Interpreting Soil Analysis.pdf",
    description: "",
    image: "images/YieldBucket.png",
    directDownload: true,
    soilArticle: true
  },
  {
    title: "Heavy Metal Guidelines in Soil",
    filename: "Lancrop_Heavy Metal Guidelines in Soil.pdf",
    description: "",
    image: "images/HeavyMetals.png",
    directDownload: true,
    soilArticle: true
  },
  {
    title: "ADAS Index System",
    filename: "Lancrop_ADAS Index System.pdf",
    description: "",
    image: "images/adas.jpg",
    directDownload: true,
    soilArticle: true
  },
  {
    title: "Soil Organic Matter",
    filename: "Lancrop_Soil Organic Matter.pdf",
    description: "",
    image: "images/organicMatter.jpg",
    directDownload: true,
    soilArticle: true
  },
  {
    title: "Understanding CEC",
    filename: "Lancrop_Understanding CEC.pdf",
    description: "",
    image: "images/cations.jpg",
    directDownload: true,
    soilArticle: true
  },
  {
    title: "PCN Testing",
    filename: "Lancrop_PCN Testing.pdf",
    description: "",
    image: "images/PotatoNematodeCysts.jpg",
    directDownload: true,
    soilArticle: true
  },
  {
    title: "Macaulay Index System",
    filename: "Lancrop_Macaulay Index System.pdf",
    description: "",
    image: "images/data.jpg",
    directDownload: true,
    soilArticle: true
  },

  {
    title: "Potato Petiole Analysis",
    filename: "Lancrop_Potato Petiole Analysis.pdf",
    description: "",
    image: "images/potatoePetiole.jpg",
    directDownload: true,
    leafArticle: true
  },
  {
    title: "Fresh Grass Analysis",
    filename: "Lancrop_Fresh Grass Analysis.pdf",
    description: "",
    image: "images/grass.jpg",
    directDownload: true,
    leafArticle: true
  },

  {
    title: "Soil Respiration Test",
    filename: "Lancrop_Soil Respiration Test.pdf",
    description: "",
    image: "images/soil.jpg",
    directDownload: true
  },

  {
    title: "Slurry and manure sampling",
    filename: "Lancrop_Slurry and manure sampling.pdf",
    description: "",
    image: "images/slurry.jpg",
    directDownload: true,
    otherArticle: true,
    samplingArticle: true
  },

  {
    title: "Fertiliser Recommendations for Multicut Silage Systems",
    filename:
      "Lancrop_Fertiliser Recommendations for Multicut Silage Systems.pdf",
    description: "",
    image: "images/silage.jpg",
    directDownload: true,
    otherArticle: true
  },
  {
    title: "Livestock Drinking Water Quality",
    filename: "Lancrop_Livestock Drinking Water Quality.pdf",
    description: "",
    image: "images/Livestock.png",
    directDownload: true,
    otherArticle: true
  },
  {
    title: "Water Quality Parameters and Guidelines",
    filename: "Lancrop_Water Quality Parameters and Guidelines.pdf",
    description: "",
    image: "images/waterSpray.jpg",
    directDownload: true,
    otherArticle: true
  },
  {
    title: "Silage Feed Guideline Values",
    filename: "Lancrop_Silage Feed Guideline Values.pdf",
    description: "",
    image: "images/silage.jpg",
    directDownload: true,
    otherArticle: true
  },
  {
    title: "Sewage Sludge Heavy Metal Guidelines",
    filename: "Lancrop_Sewage Sludge Heavy Metal Guidelines.pdf",
    description: "",
    image: "images/data.jpg",
    directDownload: true,
    otherArticle: true
  },
  {
    title: "ADAS Index System for Loamless Compost",
    filename: "Lancrop_ADAS Index System for Loamless Compost.pdf",
    description: "",
    image: "images/adas.jpg",
    directDownload: true,
    otherArticle: true
  },

  
  {
    title: "Garden Allotment Options",
    filename: "Lancrop_Garden Allotment Options.pdf",
    description: "",
    image: "images/allotment_tea.jpg",
    directDownload: true,
    otherArticle: true
  }

,

  
  {
    title: "Lancrop Sampling Poster",
    filename: "Lancrop_Soil Analysis A2 poster.pdf",
    description: "",
    image: "images/Sampling.jpg",
    directDownload: true,
    otherArticle: true
  }
,
  {
    title: "Soil Active Carbon Analysis",
    filename: "Lancrop_Active Carbon.pdf",
    description: "",
    image: "images/Sampling.jpg",
    directDownload: true,
    soilArticle: true
  }
  , 
  {
    title: "Lancrop BSE SOL Crop Specific Guidelines",
    filename: "Lancrop_BSE SOL Crop Specific Guidelines.pdf",
    description: "",
    image: "images/Sampling.jpg",
    directDownload: true,
    soilArticle: true
  }
,
  {
    title: "Lancrop Carbon Services",
    filename: "Lancrop_Carbon_Services.pdf",
    description: "",
    image: "images/Sampling.jpg",
    directDownload: true,
    soilArticle: true
  }
  
];
export default articleList;
</script>
