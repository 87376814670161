
import * as VueRouter from 'vue-router'
import './styles/top-level.scss'
import { library } from '../node_modules/@fortawesome/fontawesome-svg-core'
import { faFilePdf,faStamp } from '../node_modules/@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '../node_modules/@fortawesome/vue-fontawesome'
import 'jquery';
import 'bootstrap'; 
//import 'bootstrap/dist/css/bootstrap.min.css';

import { createApp,h } from 'vue'
import App from './App.vue'

import articleList from "./data/ArticleList.vue";
var routes=[
  {
    path: '/',
    name: 'home',
    component: ()=>import('./views/HomePage.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './views/AboutPage.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import( './views/AnalysisPage.vue')
  },
  {
    path: '/analysisSoil',
    name: 'analysisSoil',
    component: () => import( './views/AnalysisSoil.vue')
  },{
    path: '/analysisLeaf',
    name: 'analysisLeaf',
    component: () => import( './views/AnalysisLeaf.vue')
  },{
    path: '/analysisOther',
    name: 'analysisOther',
    component: () => import( './views/AnalysisOther.vue')
  },
  {
    path: '/bulletin',
    name: 'bulletin',
    component: () => import( './views/BulletinPage.vue')
  }, {
    path: '/contact',
    name: 'contact',
    component: () => import( './views/ContactPage.vue')
  }, {
    path: '/howdoi',
    name: 'thingy',
    component: () => import( './views/HowDoI.vue')
  }, {
    path: '/privacy',
    name: 'privacy',
    component: () => import( './views/PrivacyPage.vue')
  }, {
    path: '/sampling',
    name: 'sampling',
    component: () => import( './views/SampleSizeAndPackagingGuide.vue')
  }
  , {
    path: '/serviceList',
    name: 'serviceList',
    component: () => import( './views/ServiceList.vue')
  }, {
    path: '/terms',
    name: 'terms',
    component: () => import( './views/TermsAndConditions.vue')
  }
  
];

articleList.forEach(element => {
  var titleWithout=element.title.replace(/\s/g, '');
  routes.push({
    path: '/articles/'+titleWithout,
    name: 'article-'+titleWithout,
    component: () => import( './views/Articles/'+titleWithout+'.vue')
  })
});

library.add(faFilePdf,faStamp)
//Vue.config.productionTip = false


const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: VueRouter.createWebHashHistory(),
  routes, // short for `routes: routes`
})

const app = createApp({
  render:()=>h(App)
})
.component('font-awesome-icon', FontAwesomeIcon)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)

app.mount('#app')