<template>
  <div id="app">
    <TopNavResponsive></TopNavResponsive>

    <router-view></router-view>
   

    <FooterArea></FooterArea>
  </div>
</template>
<script>
//import TopNav from "@/components/TopNav.vue";
import TopNavResponsive from "@/components/TopNavResponsive.vue";
import FooterArea from "@/components/FooterArea.vue";

export default {
  components: {
  //  TopNav,
  TopNavResponsive,
    FooterArea
  }
};
</script>
<style lang='scss'>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
 
}
.middleContent {
  max-width: 908px;
  /*margin-left: auto;
  margin-right: auto;*/
  margin-top: 0rem;
  padding:0rem 0.5rem;
}
a:visted {
  color: black;
}


.article img {
  width: 100%;
}
.article img.right {
  clear: both;
  width: 50%;
  float: right;
}
.article img.left {
  clear: both;
  width: 50%;
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.article h2 {
  clear: both;
}
.article img.leftQuarter {
  clear: both;
  width: 25%;
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.article:after {
  clear: both;
}

</style>
