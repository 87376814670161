<template>

  <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">  <img alt="Vue logo" src="../assets/LancropLogo.png"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#/" >Home <span class="sr-only"></span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#/services" >Analysis</a>
      </li> 
      <li class="nav-item">
        <a class="nav-link" href="#/serviceList" >Service List</a>
      </li>
       <li class="nav-item">
        <a class="nav-link" href="#/sampling" >Packaging</a>
      </li>
       <li class="nav-item">
        <a class="nav-link" href="#/bulletin" >Technical Bulletins</a>
      </li>
       <!-- <li class="nav-item">
        <a class="nav-link" href="#/contact" >Contact Us</a>
      </li> -->
     
    </ul>
   
  </div>
</nav>


</template>
<script>
export default {
  name: "TopNavResponsive"
};
</script>

<style scoped lang='scss'>
.navbar-brand{ img{max-width:10rem;}}
</style>

